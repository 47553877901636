import { ForwardedRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from "react";
import {
	cancelAnimation,
	makeMutable,
	SharedValue,
	useReducedMotion,
	withTiming,
} from "react-native-reanimated";
import isTestUser from "src/constants/isTestUser";
import useDevice from "src/hooks/useDevice";
import useDim from "src/hooks/useDim";
import { IndividualConfettoProps } from "../components/IndividualConfetto";
import {
	BASE_CONFETTI_LOOKUP,
	CONFETTI_CANNON_PRESETS,
	CONFETTI_COLOR_PALETTES,
	DEFAULT_CONFETTI_COLOR_VARIANT,
} from "../constants";
import gaussianRandom from "../helpers/gaussianRandom";
import {
	ConfettiCannonConfig,
	ConfettiCannonHandler,
	ConfettiCannonPreset,
	ConfettiCannonProps,
} from "../types";

type UniqueConfettoProps = Omit<IndividualConfettoProps, "opacitySV" | "position" | "dim"> & {
	id: string;
	batchId: string;
};

const useConfettiCannon = ({
	ref,
}: {
	ref: ForwardedRef<ConfettiCannonHandler>;
} & ConfettiCannonProps) => {
	const dim = useDim();
	const { device } = useDevice();
	const [confettiProps, setConfettiProps] = useState<UniqueConfettoProps[]>([]);
	const queueNewConfettiBatch = useCallback(
		({
			batchId,
			config: {
				numConfettiScale = 1,
				numConfettiSpread = 0.1,
				origin: originPercent = { x: 0.5, y: 1 },
				target: targetPercent = { x: 0.5, y: 0.2 },
				velocitySpread = { x: 0.1, y: 0.1 },
				colorVariant = DEFAULT_CONFETTI_COLOR_VARIANT,
			},
			opacity,
		}: {
			batchId: string;
			config: ConfettiCannonConfig;
			opacity: SharedValue<number>;
		}) => {
			const colorPalette = CONFETTI_COLOR_PALETTES[colorVariant];
			const origin: { x: number; y: number } = {
				x: originPercent.x * dim.width,
				y: originPercent.y * dim.height,
			};
			const target: { x: number; y: number } = {
				x: targetPercent.x * dim.width,
				y: targetPercent.y * dim.height,
			};
			const confetti: UniqueConfettoProps[] = [];
			const numConfetti = Math.round(
				BASE_CONFETTI_LOOKUP[device] *
					numConfettiScale *
					(1 + (Math.random() - 0.5) * numConfettiSpread),
			);
			const velocityMultipler = 0.005;
			const baseVelocity = {
				x: velocityMultipler * (target.x - origin.x),
				y: velocityMultipler * (target.y - origin.y),
			};

			for (let i = 0; i < numConfetti; i++) {
				confetti.push({
					id: `${Math.random()}`,
					batchId,
					active: true,
					origin: {
						x: origin.x,
						y: origin.y,
					},
					target: {
						x: target.x,
						y: target.y,
					},
					color: colorPalette[i % colorPalette.length],
					velocity: {
						x:
							baseVelocity.x +
							(baseVelocity.x + (Math.random() - 0.5)) *
								(gaussianRandom() - 0.5) *
								velocitySpread.x,
						y:
							baseVelocity.y +
							(baseVelocity.y + (Math.random() - 0.5)) *
								(gaussianRandom() - 0.5) *
								velocitySpread.y,
					},
					random: {
						swayAmplitude: Math.random(),
						swayMagnitude: Math.random(),
						swayOffset: Math.random(),
					},
					opacity,
				});
			}
			setConfettiProps((prev) => [...prev, ...confetti]);
		},
		[device, dim],
	);
	const queueNewConfettiBatchRef = useRef(queueNewConfettiBatch);
	useEffect(() => {
		queueNewConfettiBatchRef.current = queueNewConfettiBatch;
	}, [queueNewConfettiBatch]);

	const timeouts = useRef<NodeJS.Timeout[]>([]);
	const reducedMotion = useReducedMotion();
	const fire = useCallback(
		(
			cannonConfig: ConfettiCannonConfig | ConfettiCannonConfig[] | ConfettiCannonPreset = {},
		) => {
			if (reducedMotion) return;
			if (isTestUser) return;
			const batchId = `${Math.random()}`;

			const cannonConfigArray =
				typeof cannonConfig === "string"
					? CONFETTI_CANNON_PRESETS[cannonConfig]
					: Array.isArray(cannonConfig)
						? cannonConfig
						: [cannonConfig ?? {}];
			for (const config of cannonConfigArray) {
				const { delay = 0 } = config;
				const handleQueue = () => {
					const { duration = 4000 } = config;
					const opacity = makeMutable(1);
					opacity.value = withTiming(0, { duration });
					queueNewConfettiBatchRef.current({
						batchId,
						config,
						opacity,
					});

					timeouts.current.push(
						setTimeout(() => {
							cancelAnimation(opacity);
							setConfettiProps((prev) =>
								prev.filter((confetto) => confetto.batchId !== batchId),
							);
						}, duration),
					);
				};
				if (delay > 0) {
					timeouts.current.push(setTimeout(handleQueue, delay));
				} else {
					handleQueue();
				}
			}
		},
		[reducedMotion],
	);
	useImperativeHandle(ref, () => ({
		fire,
	}));

	return {
		confettiProps,
	};
};
export default useConfettiCannon;
