import { ComponentProps, useMemo } from "react";
import { ColorTheme, ColorThemeType } from "./ColorTheme";
import OrbGradient from "./OrbGradient";

const UUIDSeededOrbGradient = ({
	seed,
	...props
}: ComponentProps<typeof OrbGradient> & {
	seed: string;
}) => {
	const colorTheme: ColorThemeType = useMemo(() => {
		// Hacky way to get a pseudo number from a seed
		const randomNum =
			Math.sin(seed.split("").reduce((acc, char) => acc + char.charCodeAt(0), 0)) * 10_000;

		const keys = Object.keys(ColorTheme);
		const index = Math.floor(randomNum) % keys.length;
		return keys[index] as ColorThemeType;
	}, [seed]);
	return <OrbGradient {...props} colorTheme={colorTheme} />;
};
export default UUIDSeededOrbGradient;
