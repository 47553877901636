"use client";
import React, { memo, useState } from "react";
import { useModal } from "src/modals";
import GlobalModal from "src/modals/configs/GlobalModal";
import { MDiv } from "src/swsh-native";
import AnimatedHamburger from "../AnimatedHamburger";
import { NAV_BAR_HEIGHT } from "../NavBar";
import {
	FUNCTIONAL_HAMBURGER_HTML_ID,
	FUNCTIONAL_HAMBURGER_PADDING,
	FUNCTION_HAMBURGER_SIZE,
} from "./constants";
import useFunctionalNavRoutes from "./hooks/useFunctionalNavRoutes";

const FunctionalHamburger = () => {
	const [menuIsOpen, setMenuIsOpen] = useState(false);
	const { presentModal } = useModal<GlobalModal>();
	const functionalNavRoutes = useFunctionalNavRoutes();

	const handleToggle = () => {
		setMenuIsOpen(!menuIsOpen);
		presentModal("HamburgerNav", {
			props: {
				routes: functionalNavRoutes,
				onClose: () => setMenuIsOpen(false),
			},
		});
	};

	return (
		<MDiv
			id={FUNCTIONAL_HAMBURGER_HTML_ID}
			testID="NavBar-Hamburger"
			style={{
				position: "absolute",
				height: NAV_BAR_HEIGHT,
				justifyContent: "center",
				alignItems: "center",
				left: FUNCTIONAL_HAMBURGER_PADDING,
				zIndex: 1000,
			}}
		>
			<AnimatedHamburger
				isOpen={menuIsOpen}
				onPress={handleToggle}
				size={FUNCTION_HAMBURGER_SIZE}
			/>
		</MDiv>
	);
};

export default memo(FunctionalHamburger);
