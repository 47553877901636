"use client";
import { useEffect, useRef } from "react";
import { ConfettiCannonHandler } from "src/components/ConfettiCannon/types";
import useCelebrationStore from "../stores/useCelebrationStore";

const useCelebrationProvider = () => {
	const confettiCannonRef = useRef<ConfettiCannonHandler>(null);

	useEffect(() => {
		const confettiCannonUnsubscribe = useCelebrationStore.subscribe(
			(state) => state.confettiCannonHistory,
			(confettiCannonHistory) => {
				const mostRecent = confettiCannonHistory[confettiCannonHistory.length - 1];
				if (mostRecent) {
					confettiCannonRef.current?.fire(mostRecent);
				}
			},
		);
		const { subscribeConfettiCannonRef } = useCelebrationStore.getState();
		const confettiCannonRefUnsubscribe = subscribeConfettiCannonRef(confettiCannonRef);

		return () => {
			confettiCannonUnsubscribe();
			confettiCannonRefUnsubscribe();
		};
	}, []);

	return { confettiCannonRef };
};
export default useCelebrationProvider;
