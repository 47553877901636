"use client";

import { usePathname } from "next/navigation";
import { useEffect } from "react";
import useRouteTracker from "src/stores/useRouteTracker";

const RouteTracker = () => {
	const pathname = usePathname();
	useEffect(() => {
		useRouteTracker.getState().push({
			path: pathname,
		});
	}, [pathname]);

	return null;
};
export default RouteTracker;
