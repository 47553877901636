"use client";
import { track } from "@amplitude/analytics-react-native";
import { useReportWebVitals } from "next/web-vitals";
import { amp } from "src/api/amplitude";

const ReportWebVitals = () => {
	useReportWebVitals((metric) => {
		track(amp.web.webVitals, metric);
	});

	return null;
};
export default ReportWebVitals;
