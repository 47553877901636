import { useFragment } from "@apollo/client";
import React from "react";
import { StyleProp, View, ViewStyle } from "react-native";
import UserFragmentFragmentDoc from "src/api/graphql/__generated__/documents/UserFragmentFragmentDoc";
import { smallFont } from "src/styles/fonts";
import { Link } from "src/swsh-native";
import ProfilePictureContent from "./components/ProfilePictureContent";
import ProfilePictureSubText, { LabelOptions } from "./components/ProfilePictureSubText";

export interface ProfilePictureProps {
	size?: number;
	style?: StyleProp<ViewStyle>;
	/**
	 * If true, the profile picture will be clickable and will navigate to the user's profile.
	 *
	 * @default true
	 */
	touchable?: boolean;
	userId: string | null | undefined;
	label?: LabelOptions;
	url?: string;
	testID?: string;
}

const ProfilePicture = ({
	size = 40,
	style = {},
	touchable = true,
	userId,
	label,
	testID,
}: ProfilePictureProps) => {
	const { data: user } = useFragment({
		fragment: UserFragmentFragmentDoc,
		from: {
			__typename: "User",
			userId: userId ?? "",
		},
	});
	const navigateToProfile = () => {
		if (!userId) return;
		// Not currently used
	};

	const profilePictureStyle: StyleProp<ViewStyle> = [
		{
			overflow: "hidden",
			width: size,
			height: size,
			// Squircle
			//borderRadius: 1 + 0.2 * size,
			// Circle
			borderRadius: size,
		},
		!label && style,
	];

	const ProfilePicture = !touchable ? (
		<View style={profilePictureStyle} testID={testID}>
			<ProfilePictureContent user={user} size={size} />
		</View>
	) : (
		<Link href={`/user_/${userId}`} style={profilePictureStyle} testID={testID}>
			<ProfilePictureContent user={user} size={size} />
		</Link>
	);
	return label ? (
		<View
			style={[
				style,
				{
					alignItems: "center",
				},
			]}
		>
			{ProfilePicture}
			<ProfilePictureSubText
				{...label}
				textStyle={[smallFont, label.textStyle]}
				userId={userId}
				firstName={user.firstName}
				lastName={user.lastName}
			/>
		</View>
	) : (
		ProfilePicture
	);
};
export default ProfilePicture;
