import { memo } from "react";
import Animated, { useAnimatedStyle } from "react-native-reanimated";
import { FixedPosition } from "src/styles/position";
import {
	ConfettoHeight,
	ConfettoWidth,
	ConfettoXRotMult,
	ConfettoYRotMult,
	ConfettoZRotMult,
} from "../../constants/ConfettoConstants.ts";
import useIndividualConfetto from "./hooks/useIndividualConfetto";
import { IndividualConfettoProps } from "./types";

const IndividualConfetto = (props: IndividualConfettoProps) => {
	const { reanimatedValue } = useIndividualConfetto(props);
	const { color, opacity } = props;
	const animatedStyle = useAnimatedStyle(() => {
		const { x, y, fallingProgress } = reanimatedValue.value;
		return {
			transform: [
				{ translateX: x + ConfettoWidth / 2 },
				{ translateY: y + ConfettoHeight / 2 },
				{ rotateX: `${ConfettoXRotMult * fallingProgress}rad` },
				{ rotateY: `${ConfettoYRotMult * fallingProgress}rad` },
				{ rotateZ: `${ConfettoZRotMult * fallingProgress}rad` },
			],
			opacity: opacity?.value,
		};
	}, [reanimatedValue, opacity]);

	return (
		<Animated.View
			style={[
				{
					top: 0,
					left: 0,
					position: FixedPosition,
					pointerEvents: "none",
					backgroundColor: color,
					width: ConfettoWidth,
					height: ConfettoHeight,
					zIndex: 1001,
				},
				animatedStyle,
			]}
		/>
	);
};
export default memo(IndividualConfetto);
