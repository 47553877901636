import { useMemo } from "react";
import { AnimatedMenuRoute } from "src/components/AnimatedMenu/types";
import ProfilePicture from "src/components/ProfilePicture";
import IconCommunities from "src/icons/IconCommunities";
import IconGear from "src/icons/IconGear";
import IconWheel from "src/icons/IconWheel";
import useGlobalStore from "src/stores/useGlobalStore";

const useFunctionalNavRoutes = (): AnimatedMenuRoute[] => {
	const clientId = useGlobalStore((state) => state.clientId);

	const routes = useMemo<AnimatedMenuRoute[]>(() => {
		const routes: AnimatedMenuRoute[] = [
			{
				title: "Albums",
				href: "/home",
				Icon: IconWheel,
			},
			{
				title: "Groups",
				href: "/groups",
				Icon: IconCommunities,
			},
			clientId
				? {
						title: "Profile",
						href: `/user_/${clientId}`,
						renderItem: () => <ProfilePicture userId={clientId} touchable={false} />,
					}
				: undefined,
			{
				title: "Settings",
				href: "/settings",
				Icon: IconGear,
			},
			{
				title: "Create album",
				href: "/create",
				isButton: true,
			},
		].filter((route) => route) as AnimatedMenuRoute[];
		return routes;
	}, [clientId]);

	return routes;
};

export default useFunctionalNavRoutes;
