import { useEffect, useState } from "react";
import getRandomString from "src/helpers/getRandomString";
import { create } from "zustand";

interface ActiveConfettiStore {
	idQueue: string[];
}
const useActiveConfettiStore = create<ActiveConfettiStore>()((set) => ({
	idQueue: [],
}));

/**
 * Multiple CelebrationProviders may be rendered at a time, depending on whether there's a modal visible.
 *
 * This hook ensures that only one celebration provider renders at a time
 */
const useActiveConfettiProvider = () => {
	const [id] = useState(() => getRandomString());
	const isActive = useActiveConfettiStore((state) => state.idQueue[0] === id);
	useEffect(() => {
		useActiveConfettiStore.setState((state) => ({
			// Newest goes on top
			idQueue: [id, ...state.idQueue.filter((i) => i !== id)],
		}));
		return () => {
			useActiveConfettiStore.setState((state) => ({
				idQueue: state.idQueue.filter((i) => i !== id),
			}));
		};
	}, [id]);
	return { isActive };
};
export default useActiveConfettiProvider;
