export type ColorThemeFields = {
	Primary: string;
	Secondary: string;
	Tertiary: string;
};
export const ColorTheme = {
	OrangeRed: {
		Primary: "#879AF2",
		Secondary: "#D3208B",
		Tertiary: "#FDA000",
	},

	BluePink: {
		Primary: "#A8C0ED",
		Secondary: "#F4C4EC",
		Tertiary: "#C9C2ED",
	},

	OrangePink: {
		Primary: "#FF9897",
		Secondary: "#F650A0",
		Tertiary: "#FB769B",
	},

	BlueDark: {
		Primary: "#6CE8FD",
		Secondary: "#407DB2",
		Tertiary: "#0D015B",
	},

	CyanPurple: {
		Primary: "#7BF2E9",
		Secondary: "#98A9D2",
		Tertiary: "#B65EBA",
	},

	BluePurple: {
		Primary: "#F02FC2",
		Secondary: "#AC5ED5",
		Tertiary: "#6094EA",
	},

	OrangeYellow: {
		Primary: "#FFCF1B",
		Secondary: "#FFAD1B",
		Tertiary: "#FF881B",
	},

	OrangePurple: {
		Primary: "#7117EA",
		Secondary: "#A838AB",
		Tertiary: "#EA6060",
	},

	BlueWhite: {
		Primary: "#F4E8FA",
		Secondary: "#7FD5FA",
		Tertiary: "#00C0F9",
	},

	BlueDarkBlue: {
		Primary: "#F4E8FA",
		Secondary: "#699DF3",
		Tertiary: "#6454F0",
	},

	BluePink1: {
		Primary: "#FF6CAB",
		Secondary: "#BE69D2",
		Tertiary: "#7366FF",
	},

	BlueGreen: {
		Primary: "#4BAECD",
		Secondary: "#60B8AD",
		Tertiary: "#72C192",
	},

	PinkYellow: {
		Primary: "#F0DA4B",
		Secondary: "#F86B55",
		Tertiary: "#FF005E",
	},

	BlueLightPink: {
		Primary: "#DDE0FA",
		Secondary: "#E6D6F8",
		Tertiary: "#F0CBF6",
	},

	DarkPurpleOrange: {
		Primary: "#74276C",
		Secondary: "#C53364",
		Tertiary: "#FD8263",
	},

	BlueLightGreen: {
		Primary: "#3FAADE",
		Secondary: "#6FD4D2",
		Tertiary: "#9FFFC6",
	},

	PurplePink: {
		Primary: "#73A2F5",
		Secondary: "#D8C5F3",
		Tertiary: "#E850D4",
	},
} satisfies Record<string, ColorThemeFields>;

export type ColorThemeType = keyof typeof ColorTheme;

export const getRandomColorTheme = (): ColorThemeType => {
	const keys = Object.keys(ColorTheme);
	return keys[Math.floor(Math.random() * keys.length)] as ColorThemeType;
};
