"use client";
import ConfettiCannon from "src/components/ConfettiCannon";
import useActiveCelebrationProvider from "./hooks/useActiveCelebrationProvider";
import useCelebrationProvider from "./hooks/useCelebrationProvider";

const CelebrationProvider = () => {
	const { confettiCannonRef } = useCelebrationProvider();
	const { isActive } = useActiveCelebrationProvider();

	if (!isActive) return null;
	return (
		<>
			<ConfettiCannon ref={confettiCannonRef} />
		</>
	);
};
export default CelebrationProvider;
