import { LinearGradient } from "expo-linear-gradient";
import { useMemo } from "react";
import { StyleProp, View, ViewStyle } from "react-native";
import { BlurView } from "src/swsh-native";
import { ColorTheme, ColorThemeType } from "./ColorTheme";

/**
 * Returns a random number between 0 and 1, but deterministic based on the seed.
 * dr stands for deterministicRandom
 * A bad name, but easy to use inline
 */
const dr = (seed: number, shift: number, min: number = 0, max: number = 1) => {
	// https://stackoverflow.com/a/19303725/14198228
	const x = Math.sin(seed + shift) * 10000;
	const y = x - Math.floor(x);
	return min + y * (max - min);
};
const getParams = (rand: number) => {
	let accum = 0;
	let locations = [dr(rand, 0.1, 0, 0.3)];
	while (locations.length < 3) {
		accum += locations[locations.length - 1] ?? 0;
		locations.push(
			(locations[locations.length - 1] ?? 0) +
				dr(rand, accum, 0.1, (1 - accum) / (3 - locations.length)),
		);
	}
	return {
		start: { x: dr(rand, 0.1), y: dr(rand, 0.2) },
		end: { x: dr(rand, 0.3), y: dr(rand, 0.4) },
		locations: locations as [number, number, number],
	};
};

const OrbGradient = ({
	colorTheme = "BlueLightGreen",
	random: _random,
	numGradients = 3,
	style,
	containerStyle,
	size = 32,
}: {
	colorTheme?: ColorThemeType;
	random?: number;
	numGradients?: number;
	style?: StyleProp<ViewStyle>;
	containerStyle?: StyleProp<ViewStyle>;
	size?: number;
}) => {
	const colors = ColorTheme[colorTheme];
	const random = useMemo(() => {
		if (_random) {
			return _random;
		}
		return Math.random();
	}, [_random]);
	return (
		<View
			style={[
				containerStyle,
				{
					width: size,
					height: size,
					borderRadius: size,
					overflow: "hidden",
				},
			]}
		>
			{Array.from({ length: numGradients }).map((_, i) => (
				<LinearGradient
					key={i}
					style={[
						{
							width: size,
							height: size,
							borderRadius: size,
							// Opacity progressively decreases for each layer
							opacity: 1 / numGradients + 0.1 * (numGradients - i),
							position: "absolute",
						},
						style,
					]}
					colors={
						[colors.Primary, colors.Secondary, colors.Tertiary].sort((a, b) =>
							dr(random, i, -1, 1),
						) as [string, string, string]
					}
					{...getParams(random + i)}
				/>
			))}
			<BlurView
				tint="light"
				style={[
					style,
					{
						width: size,
						height: size,
						borderRadius: size,
					},
				]}
			/>
		</View>
	);
};

export default OrbGradient;
