import { useState } from "react";
import { StyleProp, ViewStyle } from "react-native";
import { RandomOrbGradient } from "src/components/OrbGradient";
import UUIDSeededOrbGradient from "src/components/OrbGradient/UUIDSeededOrbGradient";
import { Image } from "src/swsh-native";

const ProfilePictureContent = ({
	user,
	size,
	style,
}: {
	user:
		| {
				userId?: string | null | undefined;
				profilePicture?:
					| {
							url?: string | null | undefined;
					  }
					| null
					| undefined;
		  }
		| null
		| undefined;
	size: number;
	style?: StyleProp<ViewStyle>;
}) => {
	const [loadFailed, setLoadFailed] = useState(false);

	if (user?.profilePicture?.url && !loadFailed) {
		return (
			<Image
				alt="Profile Picture"
				src={user.profilePicture.url}
				dim={{
					width: size,
					height: size,
				}}
				contentFit="cover"
				onError={() => {
					setLoadFailed(true);
				}}
				style={[
					{
						borderRadius: size / 2,
					},
					style,
				]}
			/>
		);
	}
	if (user?.userId) {
		return <UUIDSeededOrbGradient size={size} containerStyle={style} seed={user.userId} />;
	}
	return <RandomOrbGradient size={size} containerStyle={style} />;
};

export default ProfilePictureContent;
