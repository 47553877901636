import { RefObject } from "react";
import { ConfettiCannonArg, ConfettiCannonHandler } from "src/components/ConfettiCannon/types";
import getRandomString from "src/helpers/getRandomString";
import { create } from "zustand";
import { subscribeWithSelector } from "zustand/middleware";

interface CelebrationStore {
	confettiCannonHistory: ConfettiCannonArg[];
	queueConfettiCannon: (confettiCannon?: ConfettiCannonArg) => void;
	hasActiveHook: boolean;
	registerHook: () => () => void;
	subscribeConfettiCannonRef: (ref: RefObject<ConfettiCannonHandler>) => () => void;
	_confettiCannonRefLookup: Record<string, RefObject<ConfettiCannonHandler>>;
	getConfettiCannonRefs: () => RefObject<ConfettiCannonHandler>[];
}

const useCelebrationStore = create<CelebrationStore>()(
	subscribeWithSelector((set, get) => ({
		confettiCannonHistory: [],
		queueConfettiCannon: (confettiCannon = {}) => {
			set((state) => {
				return {
					confettiCannonHistory: [...state.confettiCannonHistory, confettiCannon],
				};
			});
		},
		hasActiveHook: false,
		registerHook: () => {
			set({ hasActiveHook: true });

			// We don't care about de-registering hooks yet
			return () => {};
		},
		_confettiCannonRefLookup: {},
		getConfettiCannonRefs: () => {
			return Object.values(get()._confettiCannonRefLookup);
		},
		subscribeConfettiCannonRef: (ref) => {
			const id = getRandomString();
			set((state) => {
				return {
					_confettiCannonRefLookup: {
						...state._confettiCannonRefLookup,
						[id]: ref,
					},
				};
			});
			const unsubscribe = () => {
				set((state) => {
					const newLookup = { ...state._confettiCannonRefLookup };
					delete newLookup[id];
					return {
						_confettiCannonRefLookup: newLookup,
					};
				});
			};

			return unsubscribe;
		},
	})),
);

export const getCelebrationStore = () => useCelebrationStore.getState();

export default useCelebrationStore;
