"use client";
import lazy from "src/swsh-native/lazy";
import useCelebrationStore from "../../stores/useCelebrationStore";

const CelebrationProvider = lazy(() => import("../../CelebrationProvider"), {
	loading: () => null,
});

const LazyCelebrationProvider = () => {
	const hasActiveHook = useCelebrationStore((state) => state.hasActiveHook);

	if (!hasActiveHook) return null;
	return <CelebrationProvider />;
};
export default LazyCelebrationProvider;
