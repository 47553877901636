import { ForwardedRef, forwardRef } from "react";
import useDim from "src/hooks/useDim";
import IndividualConfetto from "./components/IndividualConfetto";
import useConfettiCannon from "./hooks/useConfettiCannon";
import { ConfettiCannonHandler, ConfettiCannonProps } from "./types";

const ConfettiCannon = (props: ConfettiCannonProps, ref: ForwardedRef<ConfettiCannonHandler>) => {
	const { confettiProps } = useConfettiCannon({
		...props,
		ref,
	});
	const dim = useDim();

	return (
		<>
			{confettiProps.map((confetto) => (
				<IndividualConfetto key={confetto.id} dim={dim} {...confetto} />
			))}
		</>
	);
};
export default forwardRef(ConfettiCannon);
