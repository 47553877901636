import { StyleProp, TextStyle } from "react-native";
import { getClientId } from "src/helpers/globalHelpers";
import { Text } from "src/swsh-native";

export interface LabelOptions {
	/**
	 * @default "firstName"
	 */
	displayName?: "firstName" | "fullName";
	specialClientNameReplace?: string;
	textStyle?: StyleProp<TextStyle>;
	lowercase?: boolean;
}
const ProfilePictureSubText = ({
	displayName = "firstName",
	specialClientNameReplace,
	textStyle,
	lowercase,
	firstName,
	lastName,
	userId,
}: LabelOptions & {
	firstName: string | null | undefined;
	lastName: string | null | undefined;
	userId: string | null | undefined;
}) => {
	const toDisplay = (() => {
		if (specialClientNameReplace && userId === getClientId()) {
			return specialClientNameReplace;
		}
		if (!firstName || !lastName) return null;
		if (displayName === "firstName") {
			return firstName;
		}
		if (displayName === "fullName") {
			return firstName + " " + lastName;
		}
		return null;
	})();
	if (!toDisplay) return null;
	return <Text style={[textStyle]}>{lowercase ? toDisplay.toLocaleLowerCase() : toDisplay}</Text>;
};
export default ProfilePictureSubText;
