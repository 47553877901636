import { ConfettiCannonConfig } from "./types";

export const CONFETTI_COLOR_PALETTES = {
	Basic: [
		"#FF0000",
		"#00FF00",
		"#0000FF",
		"#FFFF00",
		"#FF00FF",
		"#00FFFF",
		"#FFA500",
		"#FF1493",
		"#FFD700",
		"#FF4500",
		"#FF69B4",
		"#FF6347",
	],
	Regal: ["#E6AF2E", "#F5D061", "#ECECEC", "#DDC906", "#C0C0C0"],
	Bland: [
		"#FFFFFF1A",
		"#FFFFFF33",
		"#FFFFFF4D",
		"#FFFFFF66",
		"#FFFFFF80",
		"#FFFFFF99",
		"#FFFFFFB3",
		"#FFFFFFCC",
		"#FFFFFFE6",
	],
	Pale: ["#ACCBFF", "#93ACFF", "#8993FF", "#A5B9F7", "#8496CB", "#B2B2B2", "#FEFEFE"],
} as const;

export const BASE_CONFETTI_LOOKUP = {
	mobile: 40,
	desktop: 80,
};
export const DEFAULT_CONFETTI_COLOR_VARIANT = "Pale" satisfies keyof typeof CONFETTI_COLOR_PALETTES;

const ConfettiCannonPresets = [
	"Center",
	"BottomCorners",
	"Surround",
	"Trifecta",
	"TrifectaButFromTheTop",
	"Rain",
] as const;

export const CONFETTI_CANNON_PRESETS: Record<
	(typeof ConfettiCannonPresets)[number],
	ConfettiCannonConfig[]
> = {
	Center: [
		{
			origin: { x: 0.5, y: 1 },
			target: { x: 0.5, y: 0.2 },
		},
	],
	BottomCorners: [
		{
			origin: { x: 0, y: 1 },
			target: { x: 0.5, y: 0.2 },
			numConfettiScale: 0.8,
			velocitySpread: {
				x: 0.2,
				y: 0.1,
			},
		},
		{
			origin: { x: 1, y: 1 },
			target: { x: 0.5, y: 0.2 },
			numConfettiScale: 0.8,
			velocitySpread: {
				x: 0.2,
				y: 0.1,
			},
		},
	],
	Surround: [
		{
			origin: { x: 0.5, y: 0 },
			target: { x: 0.5, y: 0.5 },
			numConfettiScale: 0.2,
		},
		{
			origin: { x: 0, y: 0 },
			target: { x: 0.5, y: 0.5 },
			numConfettiScale: 0.2,
			delay: 100,
		},
		{
			origin: { x: 1, y: 0 },
			target: { x: 0.5, y: 0.5 },
			numConfettiScale: 0.2,
			delay: 100,
		},
		{
			origin: { x: 0, y: 0.5 },
			target: { x: 0.5, y: 0.5 },
			numConfettiScale: 0.2,
			delay: 200,
		},
		{
			origin: { x: 1, y: 0.5 },
			target: { x: 0.5, y: 0.5 },
			numConfettiScale: 0.2,
			delay: 200,
		},
		{
			origin: { x: 0, y: 1 },
			target: { x: 0.5, y: 0.3 },
			numConfettiScale: 0.2,
			delay: 300,
		},
		{
			origin: { x: 1, y: 1 },
			target: { x: 0.5, y: 0.3 },
			numConfettiScale: 0.2,
			delay: 300,
		},
		{
			origin: { x: 0.5, y: 1 },
			target: { x: 0.5, y: 0.3 },
			numConfettiScale: 0.5,
			delay: 400,
			velocitySpread: {
				x: 0.5,
				y: 0.1,
			},
		},
	],
	Trifecta: [
		{
			origin: { x: 0.1, y: 1 },
			target: { x: 0.1, y: 0.2 },
			velocitySpread: {
				x: 0.2,
				y: 0.1,
			},
		},
		{
			origin: { x: 0.5, y: 1 },
			target: { x: 0.5, y: 0.2 },
			velocitySpread: {
				x: 0.2,
				y: 0.1,
			},
			delay: 400,
		},
		{
			origin: { x: 0.9, y: 1 },
			target: { x: 0.9, y: 0.2 },
			velocitySpread: {
				x: 0.2,
				y: 0.1,
			},
			delay: 200,
		},
	],
	TrifectaButFromTheTop: [
		{
			origin: { x: 0.1, y: -0.2 },
			target: { x: 0.3, y: 1 },
			velocitySpread: {
				x: 0.8,
				y: 0.8,
			},
		},
		{
			origin: { x: 0.5, y: -0.2 },
			target: { x: 0.5, y: 1 },
			velocitySpread: {
				x: 1.2,
				y: 0.8,
			},
		},
		{
			origin: { x: 0.9, y: -0.2 },
			target: { x: 0.7, y: 1 },
			velocitySpread: {
				x: 0.8,
				y: 0.8,
			},
		},
	],
	Rain: [
		{
			origin: { x: 0.25, y: -0.4 },
			target: { x: 0.25, y: 1 },
			velocitySpread: {
				x: 3,
				y: 1,
			},
			numConfettiScale: 0.5,
		},
		{
			origin: { x: 0.5, y: -0.4 },
			target: { x: 0.5, y: 1 },
			velocitySpread: {
				x: 3,
				y: 1,
			},
			numConfettiScale: 0.5,
		},
		{
			origin: { x: 0.75, y: -0.4 },
			target: { x: 0.75, y: 1 },
			velocitySpread: {
				x: 3,
				y: 1,
			},
			numConfettiScale: 0.5,
		},
	],
};
