import { useEffect } from "react";
import { ModalType } from "../../types";
import _useModal from "./hooks/_useModal";
import useHandleModalPrefetch from "./hooks/useHandleModalPrefetch";
import { UseModalProps, UseModalReturnType } from "./types";

const useModal = <T extends ModalType>({
	prefetch,
}: UseModalProps<T> = {}): UseModalReturnType<T> => {
	const { _presentModal, handleCloseOpenedModalsOnUnmount } = _useModal<T>();
	useHandleModalPrefetch(prefetch);

	useEffect(() => {
		return () => {
			handleCloseOpenedModalsOnUnmount();
		};
	}, [handleCloseOpenedModalsOnUnmount]);

	return {
		presentModal: _presentModal,
	};
};

export default useModal;
