import { useEffect, useState } from "react";
import { runOnJS, useFrameCallback, useSharedValue } from "react-native-reanimated";
import {
	ConfettoHeight,
	ConfettoWidth,
} from "src/components/ConfettiCannon/constants/ConfettoConstants.ts";
import { IndividualConfettoProps } from "../types";

const X_DRAG = 0.945;
/**
 * Bottom of the screen
 * In a perfect world this would be 0
 * In our real buggy world we have a margin since there's an issue where things linger on-screen with mobile screen size changes due to smart banner
 */
const BOTTOM_THRESHOLD = -200;
const useIndividualConfetto = ({
	active,
	origin,
	velocity,
	random,
	dim,
}: IndividualConfettoProps) => {
	// Avoid using useMemo in here; causes a bunch of JS thread lag
	const gravity = 0.000009 * dim.height;
	const maxSway = 0.005 * dim.width;
	const terminalVelocity = 0.00015 * dim.height;
	const terminalVelocityDeceleration = 0.0005 * dim.height;
	// Using seeded random from parent
	const swayOffset = random.swayOffset * 6.28;
	const swayMagnitude = (random.swayMagnitude + 0.2) * maxSway;
	const swayAmplitude = 0.003 * random.swayAmplitude;

	const reanimatedValue = useSharedValue({
		x: origin.x - ConfettoWidth / 2,
		y: origin.y - ConfettoHeight / 2,
		vx: velocity.x,
		vy: velocity.y,
		fallingProgress: 0,
	});
	const [complete, setComplete] = useState(false);

	const { setActive } = useFrameCallback((frameInfo) => {
		"worklet";
		const dt = frameInfo.timeSincePreviousFrame;
		if (!dt) return;
		let { x, y, vx, vy, fallingProgress } = reanimatedValue.value;

		x += vx * dt + 0.1 * swayMagnitude * Math.sin(fallingProgress + swayOffset);
		y += vy * dt;

		// Particles swaying less should have faster terminal velocity
		const curTerminalVelocity = terminalVelocity + 0.01 * (maxSway - swayMagnitude);
		vy = vy + gravity * dt;

		// Don't use dt here -- causes glitches when lagging
		vy -= terminalVelocityDeceleration * Math.max(0, vy - curTerminalVelocity);

		vx = vx * X_DRAG;

		if (vy > BOTTOM_THRESHOLD) {
			fallingProgress += swayAmplitude * dt;
		}
		if (vy > BOTTOM_THRESHOLD && y > dim.height) {
			runOnJS(setComplete)(true);
		}
		reanimatedValue.value = { x, y, vx, vy, fallingProgress };
	}, false);

	useEffect(() => {
		if (active) {
			setActive(active);
		}
	}, [active, setActive]);
	useEffect(() => {
		if (complete) {
			setActive(false);
		}
	}, [complete, setActive]);

	return {
		reanimatedValue,
	};
};
export default useIndividualConfetto;
